<cdk-virtual-scroll-viewport [itemSize]="40" [minBufferPx]="160" [maxBufferPx]="320">
  <div
    *cdkVirtualFor="let row of rows()"
    class="icon-picker-content-row"
    data-testid="icon-option-row"
    role="row"
  >
    @for (option of row; track trackByOption($index, option); let last = $last) {
      <div
        class="icon-picker-content-item"
        [class.is-selected]="option.icon === value()"
        (click)="selection.emit(option)"
        nz-tooltip
        [nzTooltipTitle]="option.text"
        nzTooltipPlacement="top"
        nzTooltipTrigger="hover"
        [class.is-last-icon-option]="last"
        data-testid="icon-option"
        title="{{option.text}}"
        role="option"
      >
        <spa-ui-icon [icon]="option.icon"></spa-ui-icon>
      </div>
    }
  </div>
</cdk-virtual-scroll-viewport>
