<form nz-form [nzLayout]="'vertical'">
  <nz-form-item>
    @if (hasLabel()) {
      <nz-form-label [nzFor]="name">
        {{ label() | text }}
      </nz-form-label>
    }

    <nz-form-control [nzValidateStatus]="validationStatus()" [nzHasFeedback]="!!feedback()">
      @if ((icon() | isString) && isClearPossible()) {
        <nz-input-group [nzPrefixIcon]="icon() | toString" [nzSuffix]="suffixTemplate">
          <ng-container [ngTemplateOutlet]="input"></ng-container>
        </nz-input-group>
      } @else if (icon() | isString) {
        <nz-input-group [nzPrefixIcon]="icon() | toString">
          <ng-container [ngTemplateOutlet]="input"></ng-container>
        </nz-input-group>
      } @else if (isClearPossible()) {
        <nz-input-group [nzSuffix]="suffixTemplate">
          <ng-container [ngTemplateOutlet]="input"></ng-container>
        </nz-input-group>
      } @else {
        <ng-container [ngTemplateOutlet]="input"></ng-container>
      }
      @if (feedback()) {
        {{ feedback() | text }}
      }
    </nz-form-control>

  </nz-form-item>
</form>

<ng-template #input>
  <input
    [id]="id()"
    nz-input
    [placeholder]="placeholder()"
    spa-ui-focus
    [ngModel]="value()"
    (ngModelChange)="onInput($event)"
    [name]="name"
    [type]="type()"
    [disabled]="disabled()"
    [nzBorderless]="borderless()"
    [nzSize]="size()"
    (keydown)="onKeyDown($event)"
    (keydown.enter)="enter.emit(true)"
  />
</ng-template>

<ng-template #suffixTemplate>
  <spa-ui-icon
    id="clear-icon"
    testId="clear-button"
    [icon]="suffix()"
    [class.visible]="value()"
    (click)="onClear();"
  ></spa-ui-icon>
</ng-template>

