import LodashFilter from 'lodash/filter';
import { isFunction, isString } from 'lodash';
import { produce } from 'immer';
import { Collection, FilterPredicateFn, FilterPredicateType } from './types';

//type Collection<T> = Array<T>;

/**
 * Filters the given collection using the provided predicate.
 *
 * @param {T} collection - The collection of items to filter.
 * @param {FilterPredicateType} predicate - The predicate to filter the collection on.
 * @returns {T} - The filtered collection.
 */
export function filter<T>(collection: Collection<T>, predicate: FilterPredicateType<T>): Collection<T> {
//  if (_.isSet(collection)) {
//    return produce<Collection<T>>(new Set<T>(), (draftState: Set<T>) => {
//      LodashFilter(collection, predicate).forEach(draftState?.add);
//    });
//  }
  return produce<any>(collection, (draftState: Collection<T>) => {
    if (isString(predicate)) {
      return LodashFilter<any>(draftState, predicate);
    } else if (isFunction(predicate)) {
      const predicateFn: FilterPredicateFn<T> = predicate;
      return LodashFilter<any>(draftState, (item: T) => predicateFn(item));
    } else {
      return LodashFilter<any>(draftState, predicate);
    }
  });
}
