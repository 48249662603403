import { ChangeDetectionStrategy, Component, computed, CUSTOM_ELEMENTS_SCHEMA, forwardRef, input, InputSignal, output, OutputEmitterRef, Signal, viewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormControlName, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import { FocusDirective } from '@smartops-monorepo/ui-core/directive';
import { IconComponent } from '../icon';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { ClientCommonModule } from '@smartops-monorepo/client-common';
import { BaseControlComponent } from '../base/base-control.abstract';

type InputType = 'text' | 'email' | 'password';
type SuffixIcon = 'close' | 'close-circle';

const TEXT_INPUT: InputType = 'text';

@Component({
  selector: 'spa-ui-text-input',
  standalone: true,
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  imports: [ClientCommonModule, IconComponent, FormsModule, NzFormModule, NzInputModule, FocusDirective],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TextInputComponent),
    multi: true,
  }],
})
export class TextInputComponent extends BaseControlComponent {
  input: Signal<FocusDirective> = viewChild.required(FocusDirective);
  label: InputSignal<string|undefined> = input();

  clear: OutputEmitterRef<any> = output<any>();
  enter: OutputEmitterRef<any> = output<any>();

  /**
   * Returns true if the label is not empty.
   *
   * @returns {boolean} - True, if the label is not empty.
   */
  hasLabel: Signal<boolean> = computed(() => isString(this.label()) && !isEmpty(this.label()));

  validationStatus: InputSignal<string | FormControl | FormControlName> = input(undefined as any);
  feedback: InputSignal<string|undefined> = input();
  icon: InputSignal<string|undefined> = input(); // '');
  type: InputSignal<InputType> = input(TEXT_INPUT);
  isClearPossible: InputSignal<boolean> = input(false);

  onlyBottomBorder: InputSignal<boolean> = input(false);
  hasIconPadding: InputSignal<boolean> = input(true);
  borderless: InputSignal<boolean> = input(false);

  suffix: Signal<SuffixIcon> = computed(() => (
    this.onlyBottomBorder() ? 'close': 'close-circle'
  ));

  private static COUNTER: number = 1;
  readonly name: string;

  constructor() {
    super('text-input');
    this.name = `spa-text-input-${TextInputComponent.COUNTER++}`;
    this.initialId = `${this.name}-id`;
  }

  onClear() {
    this.onChange(undefined);
    this.clear.emit(true);
    this.blur();
  }

  /**
   * Function to focus on input field.
   *
   * @public
   * @override
   */
  public focus() {
    this.input().focus();
  }

  public blur() {
    this.input().blur();
  }
}
