import { ChangeDetectionStrategy, Component, model, ModelSignal, OnInit, output, OutputEmitterRef, ViewEncapsulation } from '@angular/core';
import { ClientCommonModule } from '@smartops-monorepo/client-common';
import { ControlComponent } from '../control';
import { TextInputComponent } from '../text-input/text-input.component';
import { distinctUntilChanged, merge, Observable, Subject } from 'rxjs';
import { debounceTime, throttleTime } from 'rxjs/operators';

const DURATION: number = 300;

@Component({
  selector: 'spa-ui-search-input',
  standalone: true,
  imports: [ClientCommonModule, ControlComponent, TextInputComponent],
  templateUrl: './search-input.component.html',
  styleUrl: './search-input.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInputComponent implements OnInit {
  filterTextChanged: Subject<string> = new Subject<string>();
  filterTextChanged$: Observable<string> = this.filterTextChanged.asObservable();

  value: ModelSignal<string> = model('');

  onValueChange: OutputEmitterRef<string> = output();

  enter: OutputEmitterRef<string> = output();

  clear: OutputEmitterRef<string> = output();

  ngOnInit(): void {
    const throttled$: Observable<string> = this.filterTextChanged$.pipe(throttleTime(DURATION));
    const debounced$: Observable<string> = this.filterTextChanged$.pipe(debounceTime(DURATION));

    merge(throttled$, debounced$)
      .pipe(distinctUntilChanged())
      .subscribe((value: string) => {
        this.onValueChange.emit(value)
      });
  }

  onChange(value: string): void {
    this.value.set(value);
    this.filterTextChanged.next(value);
  }

  onClear(): void {
    this.value.set('');
    this.onValueChange.emit(this.value());
    this.clear.emit(this.value());
  }
}
