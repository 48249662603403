import { NgModule } from '@angular/core';
import { CoreModule } from '../core.module';
import { RouterTestingModule } from '@angular/router/testing';
import { CLIENT_CONFIG } from '../config/config.token';
import { ENVIRONMENT_CONFIG } from '../config/environment.token';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { HttpTestingModule } from './http-testing.module';

export const API_ENDPOINT = 'http://localhost:3000';
export const API_PREFIX = '/api';

@NgModule({
  imports: [HttpTestingModule, CoreModule.forRoot()],
  providers: [
    { provide: CLIENT_CONFIG, useValue: { API_ENDPOINT, API_PREFIX } },
    { provide: ENVIRONMENT_CONFIG, useValue: { production: false } },
  ],
  exports: [
    NoopAnimationsModule,
    RouterTestingModule,
  ],
})
export class ClientTestingModule {}
