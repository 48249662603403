import { produce } from 'immer';

/**
 *
 * @param {T[]} array - The array to splice.
 * @param {number} start - The index to start at.
 * @param {number} deleteCount - The number of items to delete.
 * @param {T} items - The items to insert.
 * @returns {T[]} - The spliced array.
 */
export function splice<T>(array: T[], start: number, deleteCount: number, ...items: T[]): T[] {
  return produce(array, (draftState: T[]) => {
    draftState.splice(start, deleteCount, ...items);
    return draftState;
  });
}
