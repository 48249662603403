<spa-ui-text-input
  type="text"
  icon="search"
  [placeholder]="('label:search' | text) + '...'"
  [isClearPossible]="true"
  [ngModel]="value()"
  (ngModelChange)="onChange($event)"
  (clear)="onClear()"
  (enter)="enter.emit(value())"
></spa-ui-text-input>
