import { AfterViewInit, ChangeDetectionStrategy, Component, computed, forwardRef, HostListener, input, InputSignal, Signal, signal, viewChild, ViewEncapsulation, WritableSignal } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import classNames from 'classnames';
import { NzTimePickerComponent, NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { ClientCommonModule } from '@smartops-monorepo/client-common';
import { BaseControlComponent, DateTimeValue, DisplaySize, GridSize } from '../base';

import { TimeStep } from './time-picker.interface';
import { TIME_FORMAT } from '@smartops-monorepo/core-common';

@Component({
  selector: 'spa-ui-time-picker',
  standalone: true,
  imports: [ClientCommonModule, NzTimePickerModule],
  templateUrl: './time-picker.component.html',
  styleUrl: './time-picker.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TimePickerComponent),
    multi: true,
  }],
})
export class TimePickerComponent extends BaseControlComponent implements AfterViewInit {
  timePicker: Signal<NzTimePickerComponent> = viewChild.required(NzTimePickerComponent);

  format: InputSignal<string> = input(TIME_FORMAT)
  min: InputSignal<DateTimeValue|undefined> = input(undefined as any)
  max: InputSignal<DateTimeValue|undefined> = input(undefined as any)
  initialValue: InputSignal<DateTimeValue|undefined> = input(undefined as any)

  hourStep: InputSignal<number> = input(1);
  minuteStep: InputSignal<TimeStep> = input(5 as TimeStep);
  secondStep: InputSignal<TimeStep> = input(15 as TimeStep);
  allowEmpty: InputSignal<boolean> = input(true);
  displaySize: WritableSignal<DisplaySize> = signal('browser' as DisplaySize);
  gridSize: WritableSignal<GridSize> = signal(this.getGridSize());

  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    this.displaySize.set(this.getDisplaySize());
    this.gridSize.set(this.getGridSize());
  }

  constructor() {
    super('time-picker');
  }

  public focus(): void {
    setTimeout(() => {
      this.timePicker().focus();
      this.cd.markForCheck();
    });
  }

  public ngAfterViewInit(): void {
    this.onResize();
  }

  isMobileSize: Signal<boolean> = computed(() => this.displaySize() === 'mobile');
  isTabletSize: Signal<boolean> = computed(() => this.displaySize() === 'tablet');

  popupClassNames: Signal<string> = computed(() => classNames({
    'spa-time-picker-popup--mobile': this.isMobileSize(),
    'spa-time-picker-popup--tablet': this.isTabletSize(),
  }, 'spa-time-picker-popup', `spa-time-picker-popup--${this.gridSize()}`));

  public blur(): void {
    this.timePicker().blur();
  }
}
