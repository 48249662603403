import { ChangeDetectionStrategy, Component, computed, forwardRef, input, InputSignal, Signal, TemplateRef, viewChild, ViewEncapsulation } from '@angular/core';
import { ClientCommonModule } from '@smartops-monorepo/client-common';
import { NzSelectComponent, NzSelectItemInterface, NzSelectModule } from 'ng-zorro-antd/select';
import { BaseControlComponent, DataRecord } from '../base';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { DYNAMIC_SELECT, DynamicSelectConfig, DynamicSelectControl, SelectConfig, SelectOption } from './select.interface';
import { Control } from '../control';

type OptionTemplate = TemplateRef<{ $implicit: NzSelectItemInterface }>;

@Component({
  selector: 'spa-ui-select',
  standalone: true,
  imports: [ClientCommonModule, NzSelectModule],
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectComponent),
    multi: true,
  }],
})
export class SelectComponent extends BaseControlComponent {
  select: Signal<NzSelectComponent> = viewChild.required(NzSelectComponent);
  control: InputSignal<Control> = input.required();
  data: InputSignal<DataRecord> = input<DataRecord>({} as DataRecord);
  clearable: InputSignal<boolean> = input(false);
  searchable: InputSignal<boolean> = input(false);

  config: Signal<SelectConfig|undefined> = computed(() => {
    if (!this.control()?.config) {
      return undefined;
    }
    return this.control()?.config as SelectConfig;
  });

  customTemplate: Signal<OptionTemplate|null> = computed(() => {
    if (!this.config()) {
      return null;
    }
    return this.config()?.customTemplate as OptionTemplate;
  });

  constructor() {
    super('select');
  }

  options: Signal<SelectOption[]> = computed(() => {
    const data = this.data();
    if (!this.control()?.config) {
      return [];
    }
    if (!this.isDynamicType()) {
      return get(this.config(), 'options', []);
    }
    const config = this.config() as DynamicSelectConfig;
    return config.getOptions(data, this.control() as DynamicSelectControl);
  }, { equal: isEqual })

  isDynamicType: Signal<boolean> = computed(() => (
    this.config()?.type === DYNAMIC_SELECT
  ));

  public focus(): void {
    this.select().focus();
  }

  public blur(): void {
    this.select().blur();
  }
}
