import { ChangeDetectionStrategy, Component, forwardRef, input, InputSignal, Signal, viewChild, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { ClientCommonModule } from '@smartops-monorepo/client-common';
import { FocusDirective } from '@smartops-monorepo/ui-core/directive';
import { BaseControlComponent } from '../base';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';

@Component({
  selector: 'spa-ui-number',
  standalone: true,
  imports: [ClientCommonModule, NzFormModule, NzInputModule, FocusDirective, NzDatePickerComponent],
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NumberComponent),
    multi: true,
  }],
})
export class NumberComponent extends BaseControlComponent {
  input: Signal<FocusDirective> = viewChild.required(FocusDirective);

  min: InputSignal<number|null> = input(null as any);
  max: InputSignal<number|null> = input(null as any);
  step: InputSignal<number|null> = input(null as any);

  constructor() {
    super('number');
  }

  /**
   * Function to focus on input field.
   *
   * @public
   */
  public focus(): void {
    this.input().focus();
  }

  /**
   * Function to blur the input field.
   *
   * @public
   */
  public blur(): void {
    this.input().blur();
  }
}
