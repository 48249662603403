import { LabelText } from './interface';

export const DutchLabelText: LabelText = {
  name: 'naam',
  firstName: 'voornaam',
  lastName: 'achternaam',
  email: 'email',
  phone: 'telefoonnummer',
  password: 'wachtwoord',
  date: 'datum',
  dateOfBirth: 'geboortedatum',
  birthday: 'verjaardag',
  description: 'beschrijving',
  welcomeUser: 'welkom gebruiker',
  loginToYourAccount: 'login met je account om toegang te krijgen',
  title: 'titel',

  address: 'adres',
  city: 'stad',
  state: 'staat',
  zipcode: 'postcode',
  country: 'land',
  yes: 'ja',
  no: 'nee',

  start: 'start',
  startTime: 'start tijd',
  end: 'eind',
  endTime: 'eind tijd',
  break: 'pauze',
  breakDuration: 'pauze duur',
  actually: 'werkelijk',

  edit: 'bewerk',
  editWorker: 'pass werker aan',
  login: 'login',
  logout: 'logout',
  confirm: 'bevestig',
  confirmLogout: 'Wil je uitloggen?',
  confirmAction: 'bevestig actie',
  doYouWantToStop: 'Wil je stoppen?',
  youHaveAlreadySelectedItemsThisWillClearYourSelection: 'Je hebt al items geselecteerd. Dit zal je selectie wissen!',
  doYouWantToStopSelectingShifts: 'Wil je stoppen met het selecteren van shifts?',
  selectedItems: 'geselecteerde items',
  areYouSureYouWantToApproveThese: 'Weet je zeker dat je deze wilt goedkeuren?',
  areYouSureYouWantToRejectThese: 'Weet je zeker dat je deze wilt afkeuren?',

  register: 'registreer',
  registerNow: 'registreer direct',
  rememberMe: 'onthoud mij',
  forgotPassword: 'wachtwoord vergeten',
  or: 'of',
  search: 'zoeken',
  clear: 'wissen',
  refresh: 'verversen',
  authenticate: 'authenticeren',
  haveRead: 'Ik heb gelezen en ga akkoord met de',
  agreement: 'overeenkomst',
  confirmPassword: 'Bevestig wachtwoord',
  passwordsDoNotMatch: 'De wachtwoorden die u invoert komen niet overeen!',
  pleaseConfirmPassword: 'Bevestig uw wachtwoord alstublieft!',

  actionWasSuccessful: 'De actie was successvol',
  registrationSuccessfulMessage: 'De registratie was succesvol!',
  successfullyCreatedEmployer: 'Werkgever succesvol aangemaakt!',
  profile: 'profile',

  create: 'creëer',
  creator: 'creerder',
  createNew: 'Maak nieuwe aan',
  import: 'importeer',
  save: 'opslaan',
  cancel: 'annuleren',
  copy: 'kopieer',
  remove: 'verwijder',

  shiftPlanner: 'Shift Planner',
  dashboard: 'dashboard',
  overview: 'overzicht',
  show: 'geef weer',
  showDetails: 'toon details',

  approve: 'goedkeuren',
  actions: 'acties',
  added: 'toegevoegd',
  assigned: 'toegewezen',
  accepted: 'geaccepteerd',
  applied: 'aangemeld',
  apply: 'aanmelden',
  declined: 'afgewezen',
  reject: 'weiger',
  rejected: 'geweigerd',
  available: 'beschikbaar',
  availability: 'beschikbaarheid',
  unavailable: 'onbeschikbaar',
  notAvailable: 'niet beschikbaar',
  undo: 'ongedaan maken',

  collection: 'collectie',
  collections: 'collecties',
  collectionOverview: 'collectie overzicht',

  employer: 'werkgever',
  employers: 'werkgevers',

  job: 'baan',
  jobs: 'banen',

  company: 'bedrijf',
  companies: 'bedrijven',

  employee: 'werknemer',
  employees: 'werknemers',

  planner: 'planner',
  planners: 'planners',

  worker: 'werker',
  workers: 'werkers',

  shift: 'dienst',
  shifts: 'diensten',

  workerShift: 'dienst werker',
  workerShifts: 'diensten werkers',

  shiftCreator: 'diensten maker',
  shiftDetails: 'dienst details',
  numberOfWorkers: 'aantal werkers',

  shiftWorkerRecord: 'werker-dienst item',
  shiftWorkerRecords: 'werker-dienst items',

  location: 'locatie',
  newLocation: 'nieuwe locatie',
  primaryLocation: 'main locatie',
  defaultLocation: 'default locatie',

  insight: 'inzicht',
  details: 'details',
  info: 'info',

  calendar: 'kalender',
  accept: 'accepteer',
  add: 'voeg toe',
  decline: 'weiger',
  deny: 'weiger',

  nameJob: 'naam dienst',
  from: 'van',
  till: 'tot',

  status: 'status',
  selectAll: 'selecteer alle',
  selectNone: 'selecteer geen',

  registeredShifts: 'Aangemelde shift',
  numberOfWorkedHoursThisMonth: 'Gewerkte uren deze maand',
  workedHours: 'Gewerkte uren',
  hoursLeftToRegister: 'Uren nog te registreren',
  registerForNewShift: 'Aanmelden nieuwe shift',
  viewYourHistory: 'Bekijk jouw geschiedenis',
  registerHours: 'Uren registreren',
  upcomingShifts: 'Aankomende shifts',
  noPlannedShiftsYetMessage: 'Wow, nog geen shifts ingeplanned. Plan gelijk jouw eerste shift!',
  scheduleYourFirstShiftRightAway: 'Plan gelijk jouw eerste shift!',
  scheduleShift: 'Plan diensten in',

  open: 'open',
  registered: 'geregistreerd',
  planned: 'gepland',
  scheduled: 'ingepland',
  standBy: 'stand-by',
  all: 'alle',
  history: 'geschiedenis',
  history__EN: 'history',
  sortBy: 'sorteer op',
  today: 'vandaag',
  completed: 'afgerond',

  week: 'week',
  total: 'totaal',
  difference: 'verschil',
  deviation: 'afwijking',
  timeRegistration: 'urenregistratie',
  hourOverview: 'uren overzicht',

  availableSpots: 'beschikbare plekken',
  freeSpots: 'free spots',
  spots: 'plekken',
  timeShift: 'tijd dienst',

  stop: 'stop',
  begin: 'begin',
  skip: 'overslaan',
  next: 'volgende',
  submit: 'submit',
  goBack: 'ga terug',
  enable: 'inschakelen',
  createAdminForCompany: 'Maak een admin voor het bedrijf',

  companyName: 'bedrijfsnaam',
  welcomeToTheEmployerWizard: 'Welkom bij de Employer Setup Wizard',
  employerWizardIntroText: 'Deze setup wizard is een hulpmiddel om een nieuwe Employer account te creëren. Het neemt je door alle stappen die nodig zijn om een nieuwe Employer, en alle bijhorende informatie aan te maken',

  leaveComment: 'laat een reactie achter',
  comment: 'comment',
  comments: 'comments',

  currentStatus: 'huidige status',
  approvedBy: 'goedgekeurd door',
  notVerifiedYet: 'nog niet geverifieerd',
  adjust: 'aanpassen',
  log: 'log',
  logHours: 'log uren',
  totalWorked: 'totaal gewerkt',
  totalHoursWorked: 'totaal aantal gewerkte uren',
  addNewRow: 'voeg een nieuwe rij toe',
};
