import { IsBoolean, IsDateString, IsDefined, IsMongoId, IsOptional, IsString, ValidateNested } from 'class-validator';
import { Expose, Type } from 'class-transformer';
import { ShiftPlanner } from '@smartops-monorepo/types/user';
import { CompanyDTO, LocationDTO } from '../company';
import { BaseFetchDataResultDTO } from '../table/fetch-data-result.dto';
import { PaginationDTO } from '../table';


export class PopulatedPlannerEmployerDTO {
  @Expose({ groups: [ShiftPlanner] })
  @IsMongoId()
  @IsDefined()
  _id!: string;

  @Expose({ groups: [ShiftPlanner] })
  @IsOptional()
  @IsBoolean()
  active!: boolean;

  @Expose({ groups: [ShiftPlanner] })
  @IsOptional()
  @IsString()
  description!: string;

  @Expose({ groups: [ShiftPlanner] })
  @IsOptional()
  @IsString()
  defaultLocation!: string;

  @Expose({ groups: [ShiftPlanner] })
  @IsOptional()
  @ValidateNested()
  @Type(() => CompanyDTO)
  employer!: CompanyDTO;

  @Expose({ groups: [ShiftPlanner] })
  @IsOptional()
  @IsDateString()
  createdAt!: string;

  @Expose({ groups: [ShiftPlanner] })
  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => LocationDTO)
  locations?: LocationDTO[];
}

export class FetchPlannerEmployerResultDTO extends BaseFetchDataResultDTO {
  constructor(items: PopulatedPlannerEmployerDTO[], pagination: PaginationDTO) {
    super(pagination);
    this.items = items;
  }

  @Expose()
  @ValidateNested({ each: true })
  @Type(() => PopulatedPlannerEmployerDTO)
  items!: PopulatedPlannerEmployerDTO[];
}
