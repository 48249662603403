/* eslint-disable @angular-eslint/no-output-on-prefix */
import { ChangeDetectionStrategy, Component, computed, forwardRef, HostBinding, input, InputSignal, model, ModelSignal, output, OutputEmitterRef, Signal, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { ClientCommonModule } from '@smartops-monorepo/client-common';
import { FocusDirective } from '@smartops-monorepo/ui-core/directive';
import { CheckboxComponent } from '../checkbox';
import { DatePickerComponent } from '../date-picker';
import { NumberComponent } from '../number';
import { TimePickerComponent } from '../time-picker';
import { BaseControlValueAccessor, DataRecord } from '../base';
import { TextareaComponent } from '../textarea';
import { ControlConfigPipe } from './control-config.pipe';
import { SelectComponent, SelectPipe } from '../select';
import { ControlLayout } from './control-styling.interface';
import { DisabledControlPipe, HiddenControlPipe, RequiredControlPipe, ValidationControlPipe, VisibleControlPipe } from './control-state.pipe';
import { Control } from './control.interface';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import set from 'lodash/set';
import { ColorPickerComponent } from '../color-picker';
import { IconPickerComponent } from '../icon-picker';
import { TextManager } from '@smartops-monorepo/core-common';
import { produce } from 'immer';
import isEmpty from 'lodash/isEmpty';
import { InlineInputIconWrapperComponent, InputWrapperComponent } from '../input-wrapper';

@Component({
  selector: 'spa-ui-control',
  standalone: true,
  imports: [
    CheckboxComponent, DatePickerComponent, NumberComponent,
    HiddenControlPipe, RequiredControlPipe, ControlConfigPipe, SelectComponent,
    TextareaComponent, TimePickerComponent, DisabledControlPipe, FocusDirective,
    ValidationControlPipe, VisibleControlPipe, ColorPickerComponent, SelectPipe,
    ClientCommonModule, NzInputModule, NzFormModule, InputWrapperComponent,
    IconPickerComponent, InlineInputIconWrapperComponent,
  ],
  templateUrl: './control.component.html',
  styleUrls: ['./control.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ControlComponent),
    multi: true,
  }],
})
export class ControlComponent extends BaseControlValueAccessor {

//  @Input({ alias: 'control', required: true  }) set setControl(control: Control) { this.control.set(control) }
//  control: WritableSignal<Control> = signal({} as Control);
  control: ModelSignal<Control> = model.required<Control>();
  //  control: InputSignal<Control> = input.required<Control>();

  required: Signal<boolean> = computed(() => this.control()?.required === true);

  layout: InputSignal<ControlLayout | undefined> =input(undefined as any);
  hasLayout: Signal<boolean> = computed(() => !!this.layout());
  labelType: InputSignal<'stacked'|'inline'|null> = input(null as any);

  hasFocus: InputSignal<boolean> = input(false);
  tabIndex: InputSignal<number> = input(-1);

  keyDown: OutputEmitterRef<KeyboardEvent> = output<KeyboardEvent>();
  onFocus: OutputEmitterRef<void> = output<void>();
  readonly: InputSignal<boolean> = input(false);
  formControlName: InputSignal<string | undefined> =input(undefined as any);

  data: ModelSignal<DataRecord> = model.required<DataRecord>();
//  data: InputSignal<WritableSignal<DataRecord>> = input.required();
  disabled: Signal<boolean> = computed(() => {
    const disabled = this.control()?.disabled;
    if (!disabled) {
      return false;
    }
    if (isFunction(disabled)) {
      return disabled(this.data(), this.control());
    }
    return true;
  })

  hidden: Signal<boolean> = computed(() => this.control()?.hidden === true);

  @HostBinding('hidden') get isHidden(): boolean {
    return this.hidden();
  }

  hasIcons: Signal<boolean> = computed(() => {
    return isString(this.control()?.prefixIcon) || isString(this.control()?.suffixIcon);
  });

  onInputChange = (value: any): void => {
    this.onChange(value);
    const control = this.control();
    if (isEmpty(control)) {
      return;
    }

    if (this.data() && !this.disabled()) {
      this.data.update((data: DataRecord) => produce(data, (draft: DataRecord) => {
        set(draft, control.path, value);
      }));
    }

    if (isFunction(control?.onChange)) {
      control.onChange(value, this.data(), control);
    }
  }

  placeholder: Signal<string> = computed(() => {
    if (!isString(this.control()?.label)) {
      return '';
    } else if (isString(this.control()?.placeholder)) {
      return TextManager.getTextFromCode(this.control().placeholder as string);
    } else {
      return TextManager.createPlaceholder(this.control().label as string);
    }
  });

  config: Signal<any> = computed(() => this.control()?.config);
  type: Signal<string> = computed(() => this.control()?.type);
}
