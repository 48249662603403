import { isArray, isSet, values } from 'lodash';
import { Collection } from './types';

/**
 * Transform a collection into an array.
 *
 * @param {Collection<T>} items - The items to transform into array.
 * @returns {Array<T>} - The newly transformed array.
 */
export function toArray<T>(items: Collection<T> | Set<T>): Array<T> {
  if (isArray(items) || isSet(items)) {
    return [...items];
  }
  return values(items);
}
