import { produce } from 'immer';
import LodashConcat from 'lodash/concat';
import { Collection } from './types';

/**
 * Concatenates two collections together.
 * @template T
 * @param {Collection<T>} a - The first collection used to concat.
 * @param {Collection<T>} b - The second collection used to concat.
 * @returns {Collection<T>} -  The concatenated collection.
 */
export function concat<T>(a: Collection<T>, b: Collection<T>): Collection<T> {
  return produce(a, (draftState: Collection<T>) => {
    LodashConcat(draftState as any, b);
    return draftState;
  });
}
