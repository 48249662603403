import { produce } from 'immer';
import { isArray, isSet } from 'lodash';
import { Collection, List } from './types';

///**
// * Creates a new empty collection based on the given collection param.
// *
// * @param {T[]} collection - The collection for which we need to create a new object.
// * @returns {T[]} The newly created collection.
// */
//function createNewCollection<T>(collection: Collection<T>): any {
//  if (isSet(collection)) {
//    return new Set<T>();
//  }
//  if (isArray(collection)) {
//    return new Array<T>();
//  }
//  throw new Error('Only possible to use this when adding item to either an array or a set');
//}

/**
 * Push an item to the given collection.
 *
 * @template T
 * @param {T[]} collection - The collection to push the item to.
 * @param {T} items - The item to push to the collection.
 * @returns {T[]} - The new collection with the item pushed to it.
 */
export function push<T>(collection: Collection<T> | Set<T>, ...items: T[]): any {
  if (isSet(collection)) {
    return produce(collection, (draftState: Set<T>) => {
      items.forEach((item) => draftState.add(item));
    });
  }
  if (isArray(collection)) {
    return produce(collection, (draftState: List<T>) => {
      draftState.push(...items);
    });
  }
  throw new Error(`Given collection: '${collection}' is incorrect type`);
}

export function removeFromSet<T>(collection: Set<T>, item: T): Set<T> {
  return produce(collection, (draftState: Set<T>) => {
    draftState.delete(item);
    return draftState;
  });
}
