import { NgModule } from '@angular/core';
import { AllPipes } from './pipe';
import { CoreModule } from './core.module';

@NgModule({
  imports: [CoreModule, ...AllPipes],
  declarations: [],
  exports: [CoreModule, ...AllPipes],
})
export class ClientCommonModule {}
