import { IsBoolean, IsDate, IsDateString, IsDefined, IsEnum, IsMongoId, IsNumber, IsOptional, Min, ValidateNested } from 'class-validator';
import { Expose, Type } from 'class-transformer';
import type { ShiftActionType, ShiftVerificationStatus } from '@smartops-monorepo/types/shift-planner';
import { ShiftActionTypes, ShiftVerificationStatuses } from '@smartops-monorepo/types/shift-planner';
import { ShiftPlanner, Worker } from '@smartops-monorepo/types/user';
import { SimpleWorkerDTO, WorkerDTO } from './worker.dto';
import { PopulatedShiftDTO, SimpleShiftDTO } from './shift.dto';

export class ShiftWorkerActionDTO {
  @Expose()
  @IsMongoId()
  @IsDefined()
  actionBy!: string;

  @Expose()
  @IsEnum(ShiftActionTypes)
  @IsDefined()
  actionType!: ShiftActionType;

  @Expose()
  @IsDateString()
  @IsDefined()
  timestamp!: string;
}

export class ShiftWorkerTimeAdjustmentDTO {
  @Expose({ groups: [ShiftPlanner] })
  @IsDate()
  @IsOptional()
  startTime!: Date;

  @Expose({ groups: [ShiftPlanner] })
  @IsDate()
  @IsOptional()
  endTime!: Date;

  @Expose({ groups: [ShiftPlanner] })
  @IsNumber()
  @Min(0)
  @IsOptional()
  breakDuration!: number;

  @Expose()
  @IsMongoId()
  @IsDefined()
  actionBy!: string;

  @Expose()
  @IsDateString()
  @IsDefined()
  timestamp!: string;
}

export class ShiftVerificationDTO {
  @Expose()
  @IsEnum(ShiftVerificationStatuses)
  @IsDefined()
  status!: ShiftVerificationStatus;

  @Expose()
  @IsMongoId()
  @IsDefined()
  verifiedBy!: string;

  @Expose()
  @IsDateString()
  @IsDefined()
  timestamp!: string;
}

export class BaseShiftWorkerRecordDTO {
  @Expose({ groups: [ShiftPlanner] })
  @IsMongoId()
  _id!: string;

  @Expose({ groups: [ShiftPlanner, Worker] })
  @IsEnum(ShiftActionTypes)
  @IsDefined()
  status!: ShiftActionType;

  @Expose({ groups: [ShiftPlanner, Worker] })
  @IsEnum(ShiftVerificationStatuses)
  @IsOptional()
  verificationStatus!: ShiftVerificationStatus;

  @Expose({ groups: [ShiftPlanner, Worker] })
  @IsDate()
  @IsOptional()
  startTime!: Date;

  @Expose({ groups: [ShiftPlanner, Worker] })
  @IsDate()
  @IsOptional()
  endTime!: Date;

  @Expose({ groups: [ShiftPlanner, Worker] })
  @IsNumber()
  @Min(0)
  @IsOptional()
  breakDuration!: number;

  @Expose({ groups: [ShiftPlanner] })
  @ValidateNested({ each: true })
  @Type(() => ShiftWorkerActionDTO)
  @IsDefined()
  actions!: ShiftWorkerActionDTO[];

  @Expose({ groups: [ShiftPlanner] })
  @ValidateNested({ each: true })
  @Type(() => ShiftWorkerTimeAdjustmentDTO)
  @IsOptional()
  timeAdjustments!: ShiftWorkerTimeAdjustmentDTO[];

  @Expose({ groups: [ShiftPlanner] })
  @ValidateNested({ each: true })
  @Type(() => ShiftVerificationDTO)
  @IsOptional()
  verifications!: ShiftVerificationDTO[];

  @Expose({ groups: [ShiftPlanner, Worker] })
  @IsBoolean()
  submitted!: boolean;
}

export class ShiftWorkerRecordDTO extends BaseShiftWorkerRecordDTO {
  @Expose()
  @IsMongoId()
  @IsDefined()
  shift!: string;

  @Expose()
  @IsMongoId()
  @IsDefined()
  worker!: string;
}

export class SimplePopulatedShiftWorkerRecordDTO extends BaseShiftWorkerRecordDTO {
  @Expose()
  @ValidateNested()
  @Type(() => SimpleShiftDTO)
  shift!: SimpleShiftDTO;

  @Expose()
  @IsMongoId()
  @IsDefined()
  worker!: string;
}

export class WorkerShiftActionResultDTO {
  @Expose()
  @ValidateNested()
  @Type(() => PopulatedShiftDTO)
  shift!: PopulatedShiftDTO;

  @Expose({ groups: [ShiftPlanner, Worker] })
  @ValidateNested()
  @Type(() => ShiftWorkerRecordDTO)
  workers!: ShiftWorkerRecordDTO[];

  @Expose()
  @ValidateNested()
  @Type(() => WorkerDTO)
  worker!: SimpleWorkerDTO;
}
