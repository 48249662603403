/* eslint-disable @typescript-eslint/no-unused-vars */
import { computed, inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, filter, first, map, Observable, of, ReplaySubject, switchMap } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import get from 'lodash/get';
import { findIndex, map as lodashMap } from 'lodash';
import keyBy from 'lodash/keyBy';
import { UserRole } from '@smartops-monorepo/types/user';
import { plainToInstance } from 'class-transformer';
import { ClassConstructor } from 'class-transformer/types/interfaces';
import { push, set, splice } from '@smartops-monorepo/immutable';
import { toSignal } from '@angular/core/rxjs-interop';

function  log(...args: any[]) {
//  console.log( ...args)
}

@Injectable({
  providedIn: 'root',
})
export abstract class AbstractDataService<FetchedEntity, Entity> {
  public readonly abstract url: string; // Your REST API endpoint
  protected readonly entityId: string = '_id';
  protected dataCache$: ReplaySubject<Entity[]> = new ReplaySubject(1);
//  protected dataCache: WritableSignal<Entity[]> = signal([] as Entity[]); // Cache to store data entities
  private loadingSubject = new ReplaySubject<boolean>(1);
  protected readonly http: HttpClient = inject(HttpClient);
  public data: Signal<Entity[]> = computed(() => this.dataCache());
  private notLoaded: WritableSignal<boolean> = signal(true);
//  private readyToEnrich: WritableSignal<boolean> = signal(false);
  protected readyToEnrich: BehaviorSubject<boolean> = new BehaviorSubject(this.initialValue);
  protected loaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loaded: Observable<boolean> = this.loaded$.asObservable();
  private initialized$: Signal<boolean|undefined> = toSignal(this.loaded$);
  private initialized: Signal<boolean> = computed(() => this.initialized$() === true);
//  private dataCache$: Observable<Entity[]> = toObservable(this.dataCache);
  protected entities$: Observable<Entity[]> = this.loaded$.pipe(
    filter((loaded: boolean) => loaded),
    first(),
    switchMap(() => this.dataCache$),
  );

  _dataCache$: Signal<Entity[]|undefined> = toSignal(this.dataCache$);
  dataCache: Signal<Entity[]> = computed(() => {
    const data = this._dataCache$();
    return data || [] as Entity[];
  });

  get initialValue(): boolean {
    return true;
  }

  public setReadyToEnrich = () => {
    this.readyToEnrich.next(true);
  }

  protected afterLoading() {
    this.loaded$.next(true);
  }

  hasLoadedData: Signal<boolean> = computed(() => {
    return this.initialized();
  });

  /**
   * Initializes the service by fetching data from the API if it not yet loaded.
   */
  public init(): void {
    if (!this.initialized() && this.notLoaded()) {
      this.notLoaded.set(false);
      this.loadData().subscribe({
        next: () => log('[Done Loading]')
      })
    }
  }

  // Fetch all entities
  public getEntities(): Observable<Entity[]> {
    if (!this.initialized() && this.notLoaded()) {
      this.notLoaded.set(false);
      this.loadData().subscribe({
        next: () => log('[Done Loading]'),
        error: (error) => {
          console.error(error);
        }
      });
    }
    return this.entities$;
//    return this.loaded$.pipe(
//      filter((loaded: boolean) => loaded),
//      first(),
//      switchMap(() => this.entities$),
//    );
  }

  private loadData(): Observable<Entity[]> {
    return this.http.get<FetchedEntity[]>(this.url).pipe(
      switchMap((entities: FetchedEntity[]) => this.readyToEnrich.pipe(
        filter((ready: boolean) => ready),
        switchMap(() => of(entities))
      )),
      map((entities: FetchedEntity[]) => lodashMap(entities, this.enrichEntity)),
      tap((entities: Entity[]) => {
        // Store fetched data in cache
        this.setEntities(entities);
        this.afterLoading();
      }),
      catchError(() => {
        this.afterLoading();
        this.setEntities([]);
        return of([] as Entity[]);
      }),
    );
  }

  setEntities(entities: Entity[]) {
    this.dataCache$.next(entities);
  }

  enrichEntity = (entity: FetchedEntity): Entity => {
    return entity as unknown as Entity;
  }

  enrichUpdatedEntity = (entity: FetchedEntity): Entity => {
    return entity as unknown as Entity;
  }

  /**
   *
   * @template Entity
   * @param {string} entityId - The entity id to find.
   * @return {Observable<Entity>} - An observable that resolves to the entity.
   * @public
   */
  public findEntityById(entityId: string): Observable<Entity> {
    return this.getEntities().pipe(map((entities: Entity[]) => {
      const index: number = this.getEntityIndex(entityId, entities);
      return entities[index];
    }));
  }

  protected abstract keyToGroupBy: Signal<string>;

  public entitiesGroupedById: Signal<Record<string, Entity>> = computed(() => {
    const entities: Entity[] = this.dataCache();
    return keyBy(entities, this.keyToGroupBy());
  });

  entitiesMap: Signal<Record<string, Entity>> = computed(() => {
    return this.entitiesGroupedById();
  });

  public getEntityFromGroupedBy(key: string): Entity {
    return this.entitiesGroupedById()[key];
  }

  // Create a new entity
  public addEntity<T>(entity: T, url: string = this.url): Observable<Entity> {
    return this.http.post<FetchedEntity>(url, entity).pipe(
      map(this.enrichEntity),
      tap((newEntity: Entity) => {
        this.dataCache$.next(push(this.dataCache(), newEntity));
      }), // Add new data to cache
      catchError(this.handleError<Entity>('addEntity'))
    );
  }

  public addEntities<T>(entities: T[], url: string = this.url): Observable<Entity[]> {
    return this.http.post<FetchedEntity[]>(url, entities).pipe(
      map((entities: FetchedEntity[]) => entities.map(this.enrichEntity)),
      tap((newEntities: Entity[]) => {
        this.dataCache$.next(push(this.dataCache(), ...newEntities));
      }), // Add new entities to cache
      catchError(this.handleError<Entity[]>('addEntities'))
    )
  }

  /**
   *
   * @template T
   * @param {string} entityId - The entity id associated to the instance..
   * @param {T} entity - The updated entity.
   * @param {string} [url=this.url] - THe url to use for the update.
   * @return {Observable<any>} - An observable that resolves when the entity is updated.
   */
  public updateEntity<T>(entityId: string, entity: T, url: string = this.url): Observable<any> {
    return this.http.put<FetchedEntity>(`${url}/${entityId}`, entity).pipe(
      map(this.enrichUpdatedEntity),
      tap((result: Entity) => this.addUpdatedEntity(result)),
      catchError(this.handleError<any>('updateEntity'))
    );
  }

  protected addUpdatedEntity(entity: Entity) {
    const entities: Entity[] = this.dataCache();
    const index: number = this.getEntityIndex(this.getEntityId(entity), entities);
    this.dataCache$.next(set(entities, index, entity));
  }

  protected getEntityId(entity: Entity): string {
    return get(entity, this.entityId);
  }

  /**
   * Performs a delete entity call and then deletes that entity from the cache as well.
   *
   * @param {string} entityId - The entity id to delete.
   * @param {string} [url] - The optional url value that differs from the url set on the class.
   * @return {Observable<void>} - An observable that resolves when the entity is deleted.
   * @public
   */
  public deleteEntity(entityId: string, url: string = this.url): Observable<void> {
    return this.http.delete<void>(`${url}/${entityId}`).pipe(
      tap(() => this.deleteEntityFromData(entityId)),
      catchError(this.handleError<void>('deleteEntity'))
    );
  }

  protected deleteEntityFromData(entityId: string) {
    const entities: Entity[] = this.dataCache();
    const index: number = this.getEntityIndex(entityId, entities);
    this.dataCache$.next(splice(entities, index, 1));
  }

  /**
   * Gets the index of an entity in the cache.
   *
   * @template Entity
   * @param {string} entityId - The entity id to find.
   * @param {Entity[]} [entities=this.dataCache()] - The entities to search through.
   * @return {number} - The index of the entity.
   * @public
   */
  public getEntityIndex(entityId: string, entities: Entity[] = this.dataCache()): number {
    return entities.findIndex((entity: Entity) => get(entity, this.entityId) === entityId);
  }

  /**
   * Gets an entity by id.
   *
   * @template Entity
   * @param {string} entityId - The entity id to find.
   * @return {Entity} - The entity.
   * @public
   */
  public getEntityById(entityId: string): Entity {
    const index: number = this.getEntityIndex(entityId);
    if (index === -1) {
      throw new Error(`Can not find entity with id: ${entityId}`);
    }
    return this.dataCache()[index];
  }

  // Handle HTTP operation that failed and let the app continue
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to your remote logging infrastructure
//      console.error(error); // log to console instead

      // TODO: better job of transforming error for Entity consumption
//      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result
      return of(result as T);
    };
  }

  /**
   * Upserts an entity in the cache.
   *
   * @template Entity
   * @param {Entity} entity  - The entity to upsert.
   * @param {Record<string, any>} findQuery - The query to check if the entity already exists.
   * @protected
   */
  protected upsert(entity: Entity, findQuery: Record<string, any>) {
    const currentIndex: number = findIndex(this.data(), findQuery);
    const isAddingNewEntity: boolean = currentIndex === -1;

    const entities: Entity[] = this.dataCache();
    if (isAddingNewEntity) this.dataCache$.next(push(entities, entity));
    else this.dataCache$.next(set(entities, currentIndex, entity));
  }

  /**
   * Serializes an entity using a DTO.
   *
   * @param {ClassConstructor<any>} DTO - The DTO class.
   * @param {Record<string, any>} entity - The entity to serialize.
   * @param {UserRole[]} roles - The roles to serialize the entity with.
   * @public
   */
  public serializeUsingDTO(DTO: ClassConstructor<any>, entity: Record<string, any>, roles: UserRole[]) {
    return plainToInstance(DTO, entity, {
      excludeExtraneousValues: true,
      enableImplicitConversion: true,
      groups: roles
    });
  }

  /**
   * Updates the data cache using a update function.
   *
   * @template Entity
   * @param {(entities: Entity[]) => Entity[]} callback - The callback to update the data cache.
   * @protected
   */
  protected updateData(callback: (entities: Entity[]) => Entity[]): void {
    this.dataCache$.next(callback(this.dataCache()));
  }
}
