import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AuthService, CurrentUserService, PlatformService } from './service';
import { jwtTokenInterceptor } from './interceptors/jwt-token.interceptor';
import { AllPipes } from './pipe';
import { STORAGE_KEYS, STORAGE_KEYS_DEFAULT_VALUE } from './config';
import { CookieStorageService, StorageService } from './service/storage';
import { apiPrefixInterceptor } from './interceptors/api-prefix.interceptor';
import { httpCredentialsInterceptor } from './interceptors/http-credentials.interceptor';

const MODULES = [
  RouterModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
];

@NgModule({
  imports: [...MODULES, ...AllPipes],
  declarations: [],
  exports: [...MODULES, ...AllPipes],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: StorageService,
          useClass: CookieStorageService,
        },
        {
          provide: STORAGE_KEYS,
          useValue: STORAGE_KEYS_DEFAULT_VALUE,
        },
        AuthService,
        CurrentUserService,
        PlatformService,
        provideHttpClient(withInterceptors([
          apiPrefixInterceptor,
          httpCredentialsInterceptor,
          jwtTokenInterceptor,
        ])),
      ],
    };
  }
}
