import { IsDateString, IsMongoId, IsOptional, IsString } from 'class-validator';
import { Expose } from 'class-transformer';
import { Admin, Recruiter, ShiftPlanner } from '@smartops-monorepo/types/user';
import { IBaseLocation, ILocation, INewLocation, IUpdatedLocation } from '@smartops-monorepo/types/location';

export class BaseLocationDTO implements IBaseLocation {
  @Expose()
  @IsString()
  address!: string;

  @Expose()
  @IsString()
  city!: string;

  @Expose()
  @IsString()
  @IsOptional()
  state!: string;

  @Expose()
  @IsString()
  zipcode!: string;

  @Expose()
  @IsString()
  country!: string;
}

export class NewLocationDTO extends BaseLocationDTO implements INewLocation {
  @Expose()
  @IsString()
  name!: string;
}

export class UpdatedLocationDTO extends NewLocationDTO implements IUpdatedLocation {
}

export class LocationDTO extends NewLocationDTO implements ILocation {
  @Expose({ groups: [Admin, Recruiter, ShiftPlanner] })
  @IsMongoId()
  _id!: string;

  @IsString()
  @IsOptional()
  full!: string;

  @IsOptional()
  @IsDateString()
  @Expose({ groups: [Admin, ShiftPlanner]})
  createdAt!: Date;

  @IsOptional()
  @IsDateString()
  @Expose({ groups: [Admin, ShiftPlanner]})
  updatedAt!: Date;
}
