import { ChangeDetectionStrategy, Component, HostBinding, input, InputSignal, ViewEncapsulation } from '@angular/core';
import { CoreModule } from '@smartops-monorepo/ui-core';

export const INITIAL_COLOR: string = '#31ad0a';

@Component({
  selector: 'spa-ui-indicator',
  standalone: true,
  imports: [CoreModule],
  template: '',
  styles: ':host { display: inline-flex; border-radius: 50%; }',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndicatorComponent {
  /**
   * Input for dimension value.
   * 
   * @param {number} dimension
   */
  dimension: InputSignal<number> = input(20);

  color: InputSignal<string> = input(INITIAL_COLOR);

  /**
   * Input for color value.
   *
   * @description The color of the indicator
   * @type {string}
   */
  @HostBinding('style.background-color') get backgroundColor() { return this.color(); }
  @HostBinding('style.width.px') get width(): number {return this.dimension(); }
  @HostBinding('style.height.px') get height(): number {return this.dimension(); }
}
