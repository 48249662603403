import { inject } from '@angular/core';
import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CLIENT_CONFIG } from '../config';


/**
 * Interceptor that handles adding the endpoint and api prefix to the request url.
 * This makes sure we only call the routes that are defined in the API.
 * Based of the environment, we use specific configurations for these values.
 *
 * @param {HttpRequest<unknown>} request - The request to be intercepted
 * @param {HttpHandlerFn} next - The next handler to be called
 * @return {Observable<HttpEvent<unknown>>} - The observable of the request.
 */
export const apiPrefixInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>, next: HttpHandlerFn,
  ): Observable<HttpEvent<unknown>> =>{
  const { API_ENDPOINT, API_PREFIX } = inject(CLIENT_CONFIG);
  if (!/^(http|https):/i.test(request.url)) {
    const extraSlash = request.url.startsWith('/') ? '' : '/';
    request = request.clone({ url: `${API_ENDPOINT}${API_PREFIX}${extraSlash}${request.url}` });
  }
  return next(request);
};
