import { Component, computed, HostBinding, inject, input, InputSignal, model, ModelSignal, output, OutputEmitterRef, Signal, signal, ViewEncapsulation, WritableSignal } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import mapKeys from 'lodash/mapKeys';
import { map } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadComponent, NzUploadFile } from 'ng-zorro-antd/upload';
import { CoreModule } from '@smartops-monorepo/ui-core';
import { HttpClient } from '@angular/common/http';
import { IconComponent } from '../icon';

@Component({
  selector: 'spa-ui-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CoreModule, NzUploadComponent, IconComponent],
})
export class ImageUploaderComponent {
  url: InputSignal<string> = input.required();
  name: InputSignal<string|null> = input(null as any);
  image: ModelSignal<string|null> = model(null as any);
  disabled: InputSignal<boolean> = input(false);
  large: InputSignal<boolean> = input(false);
  body: InputSignal<Record<string, string>> = input({});

  fileChange: OutputEmitterRef<any> = output<any>();
  imageChange: OutputEmitterRef<any> = output<any>();

  loading: WritableSignal<boolean> = signal(false);
  fileName: WritableSignal<string> = signal('');
  icon: WritableSignal<'loading'|'plus'> = signal(this.loading() ? 'loading' : 'plus');
  isDisabled: Signal<boolean> = computed(() => this.disabled() || !this.url());

  @HostBinding('class.large') get largeClass() { return this.large(); }

  private msg: NzMessageService = inject(NzMessageService);
  private http: HttpClient = inject(HttpClient);

  beforeUpload = (file: NzUploadFile) => {
    return new Observable((observer: Observer<boolean>) => {
      const complete = (result: any) => {
        observer.next(result);
        observer.complete();
      };

      this.fileName.set(file.name);

      if (!this.isCorrectFileType(file)) {
        this.msg.error('You can only upload JPG file!');
        complete(false);
        return;
      }
      const isLt2M = file.size ? (file.size / 1024 / 1024 < 2) : undefined;
      if (!isLt2M) {
        this.msg.error('Image must smaller than 2MB!');
        complete(false);
        return;
      }
      // check height
      this.checkImageDimension(file).then(dimensionRes => {
        if (!dimensionRes) {
          this.msg.error('Image only 300x300 above');
          complete(false);
          return;
        }

        complete(true);
      });
    });
  };

  filename: Signal<string|null> = computed(() => {
    if (!this.fileName()) {
      return null;
    } else if (!this.name()) {
      return this.fileName();
    } else {
      return `${this.name()}.${this.fileName().split('.').pop()}`;
    }
  })

  handleUpload = (item: any) => {
    const formData = new FormData();
    formData.append(item.name, item.file as any, this.filename() as string);

    console.log(`this.filename: ${this.filename()}`);

    if (this.body()) {
      mapKeys(this.body(), (value: string, key: string) => {
        formData.append(key, value);
      });
    }

    console.log(`before post`);

    return this.http.post(`${this.url()}`, formData)
      .pipe(map((res: any) => res.filename)).subscribe({
        next: (filename: string) => {
          item.onSuccess(item.file);
          this.fileChange.emit(filename);
        },
        error: (error: any) => {
          item.onError(error, item.file);
        },
      });
  };

  private isCorrectFileType(file: NzUploadFile) {
    return file.type === 'image/jpeg' || file.type === 'image/png';
  }

  private getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  }

  private checkImageDimension(file: NzUploadFile): Promise<boolean> {
    return new Promise(resolve => {
      const img = new Image(); // create image
      img.src = window.URL.createObjectURL(file as any);
      img.onload = () => {
        const width = img.naturalWidth;
        window.URL.revokeObjectURL(img.src);
        resolve(width >= 300); // width === height
      };
    });
  }

  handleChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.setLoading(true);
        break;
      case 'done':
        // Get this url from response in real world.
        this.getBase64(info.file!.originFileObj!, (img: string) => {
          this.image.set(img);
          this.imageChange.emit(this.image());
          this.setLoading(false);
        });
        break;
      case 'error':
        this.msg.error('Network error');
        this.setLoading(false);
        break;
    }
  }

  private setLoading(value: boolean) {
    this.loading.set(value);
  }

}
