import { size } from 'lodash';
import { produce } from 'immer';

/**
 * Get an immutable slice of an array.
 *
 * @param {Array<T>} array - The array to slice.
 * @param {number} begin - The beginning index to slice at.
 * @param {number} end - The end index to slice at.
 * @returns {Array<T>} - The sliced array.
 */
export function slice<T>(array: Array<T>, begin: number, end: number): Array<T> {
  if (begin < 0) {
    begin = 0;
  }

  if (end <= 0 || size(array) < end) {
    end = size(array);
  }

  return produce(array, (draftState: Array<T>) => {
    draftState.slice(begin, end);
    return draftState;
  });
}
