import { ChangeDetectionStrategy, Component, computed, forwardRef, input, InputSignal, Signal, viewChild, ViewEncapsulation } from '@angular/core';
import { FocusDirective } from '@smartops-monorepo/ui-core/directive';
import { ClientCommonModule } from '@smartops-monorepo/client-common';
import { AutoSizeType, NzInputModule } from 'ng-zorro-antd/input';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlComponent } from '../base';

@Component({
  selector: 'spa-ui-textarea',
  standalone: true,
  imports: [ClientCommonModule, NzInputModule, FocusDirective],
  templateUrl: './textarea.component.html',
  styleUrl: './textarea.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TextareaComponent),
    multi: true,
  }],
})
export class TextareaComponent extends BaseControlComponent {
  input: Signal<FocusDirective> = viewChild.required(FocusDirective);

  minRows: InputSignal<number> = input(2);
  maxRows: InputSignal<number> = input(4);

  autosize: Signal<AutoSizeType | boolean | string> = computed(() => {
    if (!this.minRows() && !this.maxRows()) {
      return false;
    }
    return { minRows: this.minRows(), maxRows: this.maxRows() };
  });

  constructor() {
    super('textarea');
  }

  /**
   * Function to focus on input field.
   *
   * @public
   */
  public focus(): void {
    this.input().focus();
  }

  /**
   * Function to blur the input field.
   *
   * @public
   */
  public blur(): void {
    this.input().blur();
  }
}
