import { Expose, Type } from 'class-transformer';
import { IsDateString, IsMongoId, IsOptional, IsString, ValidateNested } from 'class-validator';
import { LocationDTO } from './location.dto';
import { Admin, Recruiter, ShiftPlanner } from '@smartops-monorepo/types/user';


export class CompanyDTO {
  @Expose({ groups: [Admin, Recruiter, ShiftPlanner] })
  @IsMongoId()
  _id!: string;

  @Expose()
  @IsString()
  name!: string;

  @IsString({ each: true })
  @IsOptional()
  roles?: string[];

  @IsString()
  @IsOptional()
  avatar?: string;

  @ValidateNested()
  @IsOptional()
  @Type(() => LocationDTO)
  location?: LocationDTO;

  @Expose({ groups: [Admin, Recruiter, ShiftPlanner] })
  @IsOptional()
  @IsDateString()
  createdAt?: string | Date;

  @Expose({ groups: [Admin, Recruiter, ShiftPlanner] })
  @IsOptional()
  @IsDateString()
  updatedAt?: string | Date;
}
