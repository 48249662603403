import { ChangeDetectionStrategy, Component, forwardRef, input, InputSignal, Signal, viewChild, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzDatePickerComponent, NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { ClientCommonModule } from '@smartops-monorepo/client-common';
import { FocusDirective } from '@smartops-monorepo/ui-core/directive';
import { BaseControlComponent, DateTimeValue } from '../base';
import { isDateAfterMax, isDateBeforeMin, isValidDate } from './date-picker.helper';
import { DATE_FORMAT, TIME_FORMAT } from '@smartops-monorepo/core-common';
import { NzAutosizeDirective, NzInputDirective } from 'ng-zorro-antd/input';

type ModeTypes = 'date' | 'week' | 'month' | 'year';

@Component({
  selector: 'spa-ui-date-picker',
  standalone: true,
  imports: [ClientCommonModule, FocusDirective, NzDatePickerModule, NzAutosizeDirective, NzInputDirective],
  templateUrl: './date-picker.component.html',
  styleUrl: './date-picker.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatePickerComponent),
    multi: true,
  }],
})
export class DatePickerComponent extends BaseControlComponent {
  datePicker: Signal<NzDatePickerComponent> = viewChild.required(NzDatePickerComponent);

  initialValue: InputSignal<Date|undefined> = input(undefined as any)
  format: InputSignal<string> = input(`${DATE_FORMAT} ${TIME_FORMAT}`);
  mode: InputSignal<ModeTypes> = input('date' as ModeTypes);
  range: InputSignal<boolean> = input(false);
  showTime: InputSignal<boolean> = input(false);
  showToday: InputSignal<boolean> = input(true);
  showWeekNumber: InputSignal<boolean> = input(false);

  min: InputSignal<DateTimeValue|undefined> = input(undefined as any)
  max: InputSignal<DateTimeValue|undefined> = input(undefined as any)

  constructor() {
    super('date-picker');
  }

  /**
   * Checks if date is before or after configured min/max dates.
   *
   * @param {Date} current - The date to check.
   * @returns {boolean} - True if date is either before min or after max.
   */
  isDateInvalid = (current: Date): boolean => {
    if (!this.min() && !this.max()) return false;

    if (this.min() && (!isValidDate(this.min()) || isDateBeforeMin(current, this.min() as DateTimeValue))) {
      return true;
    }
    return !!(this.max() && (!isValidDate(this.max()) || isDateAfterMax(current, this.max() as DateTimeValue)));
  };

  /**
   * Set disabled state value.
   *
   * @param {boolean} isDisabled - The isDisabled value.
   * @public
   * @override
   */
  public override setDisabledState(isDisabled: boolean): void {
    this.disabled.set(this.disabled() || isDisabled);
  }

  /**
   * Focuses on the date input field.
   *
   * @public
   * @override
   */
  public focus(): void {
    this.datePicker().focus();
  }

  public blur(): void {
    if (this.datePicker().nzOpen) {
      this.datePicker().close();
    }
  }

  /**
   *
   */
  onOpenChange() {
    setTimeout(() => {
      this.cd.markForCheck();
    });
  }
}
