import { produce } from 'immer';
import LodashMap from 'lodash/map';
import { Collection, Iteratee } from './types';


/**
 * Map over the given collection using the provided iteratee.
 *
 * @param {Array<any>} collection - The collection of items to map over.
 * @param {Iteratee} iteratee - The iteratee function.
 * @returns {Array<any>} - The mapped collection.
 */
export function map<T>(collection: Collection<T>, iteratee: Iteratee<any>): Collection<T> {
  return produce(collection, (draftState: Collection<T>) => {
    return LodashMap(draftState, (item: any) => iteratee(item));
  });
}
