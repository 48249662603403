import { IndexableObject } from 'ng-zorro-antd/core/types';

export type GridBreakpoint = 'xxs'|'xs'|'sm'|'md'|'lg'|'xl'|'xxl';
export type Gutter = number|number[];

/**
 *
 * @param {Gutter} xs - The gutter for the 'xs' breakpoint
 * @param {Gutter} sm - The gutter for the 'sm' breakpoint
 * @param {Gutter} md - The gutter for the 'md' breakpoint
 * @param {Gutter} lg - The gutter for the 'lg' breakpoint
 * @param {Gutter} xl  - The gutter for the 'xl' breakpoint
 * @param {Gutter} xxl - The gutter for the 'xxl' breakpoint
 * @return {IndexableObject} - An object with keys for each breakpoint and values for the gutter for that breakpoint
 */
export function createGutterForEachBreakpoint(
  xs: Gutter, sm: Gutter, md: Gutter, lg: Gutter, xl: Gutter, xxl: Gutter,
): IndexableObject {
  return { xs, sm, md, lg, xl, xxl }
}

export const xxs: GridBreakpoint= 'xxs';
export const xs: GridBreakpoint= 'xs';
export const sm: GridBreakpoint= 'sm';
export const md: GridBreakpoint= 'md';
export const lg: GridBreakpoint= 'lg';
export const xl: GridBreakpoint= 'xl';
export const xxl: GridBreakpoint= 'xxl';

export const XS = 480
export const SM = 576
export const MD = 768;
export const LG = 992;
export const XL = 1200;
export const XXL = 1600;

export const XXS_MIN = 0;
export const XXS_MAX = XS - 1;

export const XS_MIN = XS
export const XS_MAX = SM - 1;

export const SM_MIN = SM;
export const SM_MAX = MD -1;

export const MD_MIN = MD;
export const MD_MAX = LG - 1;

export const LG_MIN = LG;
export const LG_MAX = XL - 1;

export const XL_MIN = XL;
export const XL_MAX = XXL - 1;

export const XXL_MIN = XXL;
export const XXL_MAX = Infinity;


export function determineCurrentGridBreakpoint(screenWidth: number): GridBreakpoint {
  if (screenWidth >= XXL) return xxl;
  if (screenWidth >= XL) return xl;
  if (screenWidth >= LG) return lg;
  if (screenWidth >= MD) return md;
  if (screenWidth >= SM) return sm;
  if (screenWidth >= XS) return xs;
  return xxs;
}
