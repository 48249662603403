import { produce } from 'immer';
import LodashSet from 'lodash/set';

/**
 * Sets the value at path of object. If a portion of path doesn't exist, it's created.
 *
 * @param {T} item - The item to update.
 * @param {string|number} key - The key under which to update
 * @param {*} value - The value to update
 * @returns {T} - The updated item.
 */
export function set<T extends object>(item: any, key: string | number, value: any): any {
  return produce<T>(item, (draftState: any) => {
    LodashSet(draftState, key, value);
    return draftState;
  });
}
