import { computed, Directive, effect, ElementRef, inject, input, InputSignal, signal, Signal, WritableSignal } from '@angular/core';
import isBoolean from 'lodash/isBoolean';
import isFunction from 'lodash/isFunction';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[spa-ui-focus]',
  exportAs: 'spa-ui-focus',
  standalone: true,
})
export class FocusDirective {
//  private element: ElementRef = inject(ElementRef).nativeElement as HTMLInputElement;
  private element: WritableSignal<ElementRef> = signal(inject(ElementRef));
  private nativeElement: Signal<HTMLInputElement> = computed(() => (
    this.element().nativeElement as HTMLInputElement
  ));
  private isFocussed: WritableSignal<boolean> = signal(false);

  hasFocus: InputSignal<boolean|undefined> = input(undefined as any);

  /**
   * @constructor
   */
  constructor() {
    effect(() => {

      if (isBoolean(this.hasFocus())) {
        if (this.hasFocus()) {
          this.focus();
        } else if (this.isFocussed()) {
          this.blur();
        }
      }
    }, { allowSignalWrites: true });
  }

  /**
   * Focuses the element.
   */
  focus() {
    this.isFocussed.set(true);
    this.nativeElement().focus();
    if (isFunction(this.nativeElement().select))  {
      this.nativeElement().select();
    }
  }

  /**
   * Blurs the element.
   */
  blur() {
    this.isFocussed.set(false);
    this.nativeElement().blur();
  }

}
