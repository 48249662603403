import { produce } from 'immer';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import { toArray } from './toArray';
import { Collection, Dictionary } from './types';

/**
 * Transform a collection into a map.
 *
 * @param {Collection<T>} items - The items to transform into map.
 * @param {string} [key='_id'] - The key to use as the key in the map.
 * @returns {Record<string, T>} - The transformed map.
 */
export function toMap<T>(items: Collection<T>, key: string | undefined = '_id'): Dictionary<T> {
  const list = toArray(items);
  return produce({} as Dictionary<T>, (draftState: Dictionary<T>) => {
    forEach(list, (item: T) => {
      const itemKey = get(item, key ?? '_id')
      draftState[itemKey] = item;
    });
    return draftState;
  });
}
