import { BoundFunctions } from '@testing-library/dom';
import { Queries } from '@testing-library/dom/types/get-queries-for-element';
import * as queries from '@testing-library/dom/types/queries';
import { within } from '@testing-library/angular';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

export type Screen<Q extends Queries = typeof queries> = BoundFunctions<Q>;
export type Within<Q extends Queries = typeof queries> = BoundFunctions<Q>;
export type Container = Screen | Within;

export type GetControlInputOptions = {
  control?: any;
  input?: any;
};

export class ElementTestingHelper {
  constructor(private readonly element: Element, private readonly container: Container) {}

  isContainingTestId = (testId: string): boolean  => {
    return !isNil(this.container.queryByTestId(testId));
  }

  isContainingRole = (role: string): boolean => {
    return !isNil(this.container.queryByRole(role));
  }
}

export class AngularTestingLibraryHelper {
  constructor(private readonly container: Container) {}

  getInputFromControl(controlTestId: string, inputRole: string, options: GetControlInputOptions = {}) {
    const controlWrapper = this.getByTestId(controlTestId, get(options, 'control', {}));
    return this.createWithin(controlWrapper).getByRole(inputRole, get(options, 'input', {}));
  }

  getTextInputFromControl(options: any = {}) {
    return this.getInputFromControl('text-input', 'textbox', options);
  }

  getTextAreaFromControl(options: any = {}) {
    return this.getInputFromControl('textarea', 'textbox', options);
  }

  getElement(type: string, element: HTMLElement): HTMLTextAreaElement {
    if (!isNil(element)) throw new Error('Element is not defined');
    return (element as HTMLElement as any).querySelector(type);
  }

  getByTestId(testId: string, options: any = {}) {
    return this.container.getByTestId(testId, options);
  }

  getByRole(role: string, options: any = {}) {
    return this.container.getByRole(role, options);
  }

  createWithin(element: HTMLElement): AngularTestingLibraryHelper {
    return new AngularTestingLibraryHelper(within(element));
  }

  createElementTester(element: Element): ElementTestingHelper {
    return new ElementTestingHelper(element, this.container);
  }
}
