import { inject } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { AuthService } from '../service';
import { CLIENT_CONFIG, ClientConfig } from '../config';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import noop from 'lodash/noop';

/**
 * Intercepts the request and adds the JWT Token to the request.
 *
 * @param {HttpRequest<unknown>} request - The request to be intercepted
 * @param {HttpHandlerFn} next - The next handler to be called
 * @return {Observable<HttpEvent<unknown>>} - The observable of the request.
 */
export const jwtTokenInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>, next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> =>{
  const authService: AuthService = inject(AuthService);
  const { API_ENDPOINT, API_PREFIX }: ClientConfig  = inject(CLIENT_CONFIG);
  const isRefreshTokenRequest = request.url.startsWith(`${API_ENDPOINT}${API_PREFIX}/auth/refresh`);


  /**
   * Refreshes the JWT Token if the request is not a refresh token request and the status is 401.
   *
   * @returns {Observable<HttpEvent<any>>} The request that gets refresh token.
   */
  const refreshTokenMethod= (): Observable<HttpEvent<unknown>> => {
    return fromPromise(authService.refreshAccessToken()).pipe(
      switchMap(() => next(request)),
      catchError((error: HttpErrorResponse) => {
        if (error?.status === 403) {
          authService.logoutActions().then(noop);
        }
        return throwError(() => error);
      }),
    );
  }

  return next(request).pipe(catchError((error: HttpErrorResponse) => {
    if (error?.status === 401 && !isRefreshTokenRequest) {
      return refreshTokenMethod();
    }
    return throwError(() => error);
  }));

}
