import { format, isSameHour, isSameMinute, startOfDay } from 'date-fns';
import get from 'lodash/get';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isoWeek from 'dayjs/plugin/isoWeek';
import localeData from 'dayjs/plugin/localeData';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import 'dayjs/locale/nl';

dayjs.extend(duration)
dayjs.extend(isoWeek);
dayjs.extend(localeData);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(weekOfYear);

dayjs.locale('nl');
dayjs.tz.setDefault('Europe/Amsterdam');

type DateString = Date|string;

/**
 * Creates a date time parser.
 *
 * @param {string} dateTimeFormat
 * @return {(item: any, data: Record<string, any>) => string}
 */
function createDateTimeParser(dateTimeFormat: string): (item: any, data: Record<string, any>) => string {
  return (item: any, data: Record<string, any>): string => {
    const dateTime = get(data, item.path);
    return dateTime ? format(new Date(dateTime), dateTimeFormat) : '';
  };
}

export const DAY_MONTH_YEAR_FORMAT: string = 'dd.MM.yyyy';
export const DAY_MONTH_TEXT_YEAR_FORMAT = 'dd MMM, yyyy';
export const DATE_SHORT_WEEKDAY_FORMAT = `ccc ${DAY_MONTH_TEXT_YEAR_FORMAT}`;

export const DATE_FORMAT: string = 'yyyy-MM-dd';
export const TIME_FORMAT: string = 'HH:mm';
//const DATE_FULL_WEEKDAY_FORMAT = `cccc ${DAY_MONTH_TEXT_YEAR_FORMAT}`;
export const dateParser = createDateTimeParser(DATE_SHORT_WEEKDAY_FORMAT);
export const timeParser = createDateTimeParser(TIME_FORMAT);
export const currentDay = startOfDay(new Date()).toISOString();

/**
 * Checks if two dates have the same time.
 *
 * @param {Date} time1 - The first time.
 * @param {Date} time2 - The second time.
 * @return {boolean} - True if the two times are equal, false otherwise.
 */
export function isTimeEqual(time1: Date, time2: Date): boolean {
  return isSameHour(time1, time2)
    && isSameMinute(time1, time2);
}

/**
 * Extracts the year, month and day from a date.
 *
 * @param {DateString} date - The date to extract the year, month and day from.
 * @return {[number, number, number]} - The year, month and day extracted from the date.
 */
export function getDateYearMonthDayValues(date: DateString): [number, number, number] {
  const value = dayjs(date);
  return [value.year(), value.month(), value.date()];
}

/**
 * Extracts the hour and minute from a given date.
 *
 * @param {Date | string} date - The date from which to extract the hour and minute.
 * @return {[number, number]} - An array containing the hour and minute values.
 */
export function getHourMinuteFromDate(date: DateString): [number, number] {
  const value = dayjs(date);
  return [value.hour(), value.minute()];
}

/**
 * Merges the year, month and day from date and hour and minute from startTime.
 *
 * @param {Date | string} date - The date to extract the year, month and day from.
 * @param {Date | string} startTime - The date from which to extract the hour and minute.
 * @return {Date} - The new date value.
 */
export function mergeLeftDateWithRightTime(date: DateString, startTime: DateString): Date {
  const [year, month, day]: [number, number, number] = getDateYearMonthDayValues(date);
  const [hours, minutes] = getHourMinuteFromDate(startTime);
  return new Date(year, month, day, hours, minutes);
}

export function addDateToStartAndEndTime(date: DateString, startTime: DateString, endTime: DateString): { startTime: DateString, endTime: DateString } {
  return {
    startTime: mergeLeftDateWithRightTime(date, startTime).toISOString(),
    endTime: mergeLeftDateWithRightTime(date, endTime).toISOString(),
  }
}

/**
 * Extracts the time from a date.
 *
 * @param {DateString} date - The date to extract the time from.
 * @return {string} - The time extracted from the date.
 */
export function extractTimeFromDate(date: DateString): string {
  return format(date as any, TIME_FORMAT);
}

export type Timeframe = { startDate: dayjs.Dayjs, endDate: dayjs.Dayjs, breakDuration: number };
export type TimeframeDuration = { hour: number, minute: number, break: number, total: dayjs.Dayjs };

export function getStartAndEndDate(year: number, week: number) {
  const date: dayjs.Dayjs = dayjs().tz().year(year).week(week); // ('Europe/Amsterdam'); // .add(2, 'hour');
  const startDate: dayjs.Dayjs = date.startOf('week');
  const endDate: dayjs.Dayjs = date.endOf('week');
  return { startDate: startDate.toDate(), endDate: endDate.toDate() };
}

export function getTimeframe(value: { date: DateString, startTime: DateString, endTime: DateString, breakDuration: number }): Timeframe {
  const { date, startTime, endTime, breakDuration } = value || {};
  const startDate: dayjs.Dayjs = dayjs(mergeLeftDateWithRightTime(date, startTime)).tz();
  const endDate: dayjs.Dayjs = dayjs(mergeLeftDateWithRightTime(date, endTime)).tz();
  return { startDate, endDate, breakDuration };
}


export function getDurationForTimeframe(startDate: dayjs.Dayjs, endDate: dayjs.Dayjs, breakDuration: number): TimeframeDuration {
  const endWithBreakRemoved = endDate.subtract(breakDuration, 'minute');
  return {
    hour: endWithBreakRemoved.diff(startDate, 'hour'),
    minute: endWithBreakRemoved.diff(startDate, 'minute') % 60,
    break: breakDuration,
    total: endWithBreakRemoved,
  };
}

export { dayjs };
