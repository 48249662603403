import { inject } from '@angular/core';
import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { E2E, ENVIRONMENT_CONFIG, EnvironmentConfig } from '../config';

/**
 * Interceptor that handles the configuration of the request credentials option
 * and injects it into the request. This is only done when the environment is E2E.
 *
 * @param {HttpRequest<unknown>} request - The request to be intercepted
 * @param {HttpHandlerFn} next - The next handler to be called
 * @return {Observable<HttpEvent<unknown>>} - The observable of the request.
 */
export const httpCredentialsInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>, next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> =>{
  const environmentConfig: EnvironmentConfig = inject(ENVIRONMENT_CONFIG);
  if (environmentConfig.type === E2E) {
    return next(request);
  }
  request = request.clone({ withCredentials: true });
  return next(request);
}
