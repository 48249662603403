<spa-ui-button
  class="ant-color-picker-trigger"
  nz-popover
  nzPopoverTrigger="click"
  [nzPopoverTitle]="title"
  [nzPopoverContent]="content"
  nzPopoverOverlayClassName="icon-picker-popover"
  nzPopoverPlacement="right"
  [disabled]="disabled()"
  styling="padding: 0; justify-content: center; align-items: center;"
>
  <spa-ui-icon [icon]="value()"></spa-ui-icon>
</spa-ui-button>

<ng-template #title>
  <spa-ui-text
    style="display: flex; margin: 6px 0;"
    [ngModel]="searchValue()"
    (ngModelChange)="searchValue.set($event)"
  ></spa-ui-text>
</ng-template>

<ng-template #content>
  <spa-ui-icon-picker-content
    [options]="options()"
    [value]="value()"
    (selection)="onSelection($event)"
  >
  </spa-ui-icon-picker-content>
<!--  <div style="display: flex; flex-direction: row; flex-wrap: nowrap; width: 320px; height: 240px; overflow-y: scroll;">-->
<!--    <div *ngFor="let option of options(); let i = index" [class.is-selected]="option.icon === value"-->
<!--      style="flex: 40px 0 0; height: 40px; justify-content: center; align-items: center; display: flex; font-size: 20px; cursor: pointer;">-->
<!--      <spa-ui-icon [icon]="option.icon"></spa-ui-icon>-->
<!--    </div>-->
<!--  </div>-->
</ng-template>
