import { ChangeDetectionStrategy, Component, computed, input, InputSignal, output, OutputEmitterRef, Signal, ViewEncapsulation } from '@angular/core';
import { IconOption } from './icons.constant';
import reduce from 'lodash/reduce';
import { CoreModule } from '@smartops-monorepo/client-common';
import { NzToolTipComponent, NzTooltipDirective } from 'ng-zorro-antd/tooltip';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { IconComponent } from '../icon';

type IconRow = IconOption[];

@Component({
  selector: 'spa-ui-icon-picker-content',
  standalone: true,
  imports: [CoreModule, IconComponent, NzToolTipComponent, NzTooltipDirective, ScrollingModule],
  templateUrl: './icon-picker-content.component.html',
  styleUrls: ['./icon-picker-content.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconPickerContentComponent {
  height: number = 260;
  width: number = 395;

  numberOfItems: InputSignal<number> = input(8);

  options: InputSignal<IconOption[]> = input([] as IconOption[]);
  value: InputSignal<string|undefined> = input();

  rows: Signal<IconRow[]> = computed(() => {
    const options = this.options();
    const numberOfItems = this.numberOfItems();
    const rows: IconRow[] = [];

    return reduce(options, (result, option, index) => {
      const rowIndex = Math.floor(index / numberOfItems);
//      const columnIndex = index % numberOfItems;

      if (!rows[rowIndex]) {
        rows[rowIndex] = [];
      }
      rows[rowIndex].push(option);

      return rows;
    }, [] as IconRow[]);
  })

  selection: OutputEmitterRef<IconOption> = output();

  trackByOption(index: number, option: IconOption) {
    return option.icon || index;
  }
}
