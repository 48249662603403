import { IsDate, IsDateString, IsDefined, IsMongoId, IsNumber, IsOptional, IsPositive, Min, MinDate, ValidateNested } from 'class-validator';
import { IsAfter, IsBefore } from '../../validation';
import { Expose, Type } from 'class-transformer';
import { PlanableJobDTO } from './planable-job.dto';
import { CompanyDTO, JobDTO, LocationDTO } from '../company';
import { ShiftPlanner, Worker } from '@smartops-monorepo/types/user';
import { BaseFetchDataResultDTO } from '../table/fetch-data-result.dto';
import { PaginationDTO } from '../table';

export class BaseShiftDTO {
  @IsDate()
  @MinDate(new Date())
  @Expose()
  date!: Date;

  @IsDate()
  @IsDateString()
  @IsBefore('endTime')
  @Expose()
  startTime!: Date|string;

  @IsDate()
  @IsDateString()
  @IsAfter('startTime')
  @Expose()
  endTime!: Date|string;

  @IsNumber()
  @Min(0)
  @Expose()
  breakDuration!: number;

  @IsNumber()
  @IsPositive()
  @Expose({ groups: [ShiftPlanner] })
  numberOfWorkers!: number;
}

export class SimpleShiftDTO extends BaseShiftDTO {
  @Expose({ groups: [ShiftPlanner] })
  @IsMongoId()
  @IsDefined()
  _id!: string;

  @Expose({ groups: [ShiftPlanner] })
  @IsMongoId()
  @IsDefined()
  planableJob!: string;

  @Expose({ groups: [ShiftPlanner] })
  @IsMongoId()
  @IsDefined()
  employer!: string;

  @Expose({ groups: [ShiftPlanner] })
  @IsNumber()
  @IsOptional()
  registeredWorkers!: number;
}

export class ShiftUpdateDTO {
  @IsDateString()
  @Expose()
  date!: string;

  @IsDateString()
  @Expose()
  startTime!: string;

  @IsDateString()
  @Expose()
  endTime!: string;

  @IsNumber()
  @Min(0)
  @Expose()
  breakDuration!: number;

  @IsNumber()
  @Min(1)
  @Expose()
  numberOfWorkers!: number;
}

export class NewShiftDTO extends BaseShiftDTO {
  @ValidateNested()
  @IsDefined()
  @Type(() => PlanableJobDTO)
  @Expose()
  planableJob!: PlanableJobDTO;
}

export class CreatedNewShiftDTO extends NewShiftDTO {
  @Expose()
  @IsMongoId()
  @IsDefined()
  _id!: string;
}

export class ShiftDTO extends BaseShiftDTO {
  @Expose()
  @IsMongoId()
  @IsDefined()
  _id!: string;

  @Expose()
  @IsOptional()
  @ValidateNested()
  @Type(() => PlanableJobDTO)
  planableJob!: PlanableJobDTO;

  @Expose()
  @IsOptional()
  @ValidateNested()
  @Type(() => CompanyDTO)
  planner!: CompanyDTO;

  @Expose()
  @IsOptional()
  @ValidateNested()
  @Type(() => CompanyDTO)
  employer!: CompanyDTO;

  @Expose({ groups: [ShiftPlanner] })
  @IsNumber()
  @IsOptional()
  registeredWorkers!: number;
}

export class PopulatedShiftDTO extends ShiftDTO {
  @Expose()
  @IsOptional()
  @ValidateNested()
  @Type(() => JobDTO)
  job!: JobDTO;

  @Expose()
  @IsOptional()
  @ValidateNested()
  @Type(() => LocationDTO)
  location!: LocationDTO;

  @Expose({ groups: [ShiftPlanner, Worker ] })
  @IsNumber()
  @IsOptional()
  availableSpots!: number;
}

export class FetchShiftsResultDTO extends BaseFetchDataResultDTO {
  constructor(items: PopulatedShiftDTO[], pagination: PaginationDTO) {
    super(pagination);
    this.items = items;
  }

  @Expose()
  @ValidateNested({ each: true })
  @Type(() => PopulatedShiftDTO)
  items!: PopulatedShiftDTO[];

}
