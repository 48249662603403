import { NgModule } from '@angular/core';
import { provideHttpClientTesting } from '@angular/common/http/testing';
import { provideHttpClient } from '@angular/common/http';

@NgModule({
  imports: [],
  providers: [
    provideHttpClient(),
    provideHttpClientTesting(),
  ],
})
export class HttpTestingModule {}
